import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import { LaTeX } from "latex2react"

const tex = `$$`

const HomePage = () => (
  <Layout>
    <a href="https://github.com/pyramation/LaTeX2HTML5">
      <img
        style={{
          position: "fixed",
          zIndex: 10000000,
          top: 0,
          right: 0,
          border: 0,
        }}
        src="https://s3.amazonaws.com/github/ribbons/forkme_right_orange_ff7600.png"
        alt="Fork me on GitHub"
      />
    </a>
    <div className="hero-unit">
      <div style={{ width: "100%", textAlign: "center" }}>
        <iframe
          width={420}
          height={315}
          src="https://www.youtube.com/embed/QYMLMUKJyFc"
          frameBorder={0}
          allowFullScreen
        />
      </div>
      <hr />
      <div style={{ display: "none" }}>
        {/* trick to get the \LaTeX to render on this page */}
        <LaTeX content={tex} />
      </div>
      <h3>
        Author interactive math textbooks online using $\LaTeX$ and PSTricks in
        real-time
      </h3>
      <hr />
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src="app/assets/img/splash.jpg" />
      </div>
      <hr />
      <p>
        This project extends the great work of{" "}
        <a href="http://www.mathjax.org/">MathJax</a> to enable real-time,
        dynamic authorship of mathematical ebooks.
      </p>
      <hr />
      <h3>Consistent diagrams and mathematics on PDF or any device</h3>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src="/app/assets/img/photo.png" />
      </div>
      <hr />
      <p>Works on iPhone, iPad, Nexus Tablets, and Androids</p>
      <hr />
      <h3>Author for both interactive Web and Paper</h3>
      <hr />
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src="/app/assets/img/devices.jpg" />
      </div>
      <hr />
      <p>Remember paper? Well, now you can have best of both worlds.</p>
      <hr />
      <h3>Author PSTricks graphics in real-time</h3>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src="/app/assets/img/realtime.png" />
      </div>
      <hr />
      <p>
        Real-time editor gives you instantaneous feedback with consistent
        PSTricks drawing capabilities
      </p>
      <hr />
    </div>
  </Layout>
)

export default HomePage
